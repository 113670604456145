<template>
  <div>
    <div
      v-if="widgetType === 'numbers'"
      class="NumbersContainer"
    >
      <div class="NumbersContainer-numbers">
        <div
          class="Number"
          v-for="(item, index) in counterAsParts"
          v-bind:key="index"
        >
          <div class="Number-shadow"></div>
          <div class="Number-item">
            <span class="Number-itemText">{{ item }}</span>
          </div>
        </div>
      </div>
      <h3 class="NumbersContainer-title">{{ title }}</h3>
      <small
        style="display: none"
        class="version"
      >
        pu-form-counter version: {{ version }}
      </small>
    </div>

    <div
      v-if="widgetType === 'progress'"
      class="ProgressContainer"
    >
      <div class="campaign-counter">
        <div class="small-12">{{ title }}
          <div class="pc-progress">
            <span
              class="pc-meter"
              :style="{'width': `${progressPercentage}%`}"
            />
          </div>
          <span class="pc-total">{{counter}} van de {{progressGoal}}</span>
        </div>
      </div>
    </div>

    <div
      v-if="widgetType === 'amount'"
      class="AmountContainer"
    >
      <div
        class="AmountContainer-percentage"
        :data-pct="amountPercentage"
      >
        <svg
          id="svg"
          width="200"
          height="200"
          viewPort="0 0 100 100"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            r="90"
            cx="100"
            cy="100"
            fill="transparent"
            stroke-dasharray="565.48"
            stroke-dashoffset="0"
          />
          <circle
            id="bar"
            :r="radius"
            cx="100"
            cy="100"
            fill="transparent"
            stroke-dasharray="565.48"
            :stroke-dashoffset="0"
            :style="{'strokeDashoffset': strokeDashoffset}"
          />
        </svg>
      </div>

      <div class="AmountContainer-info">
        <div class="ac-info">
          <div class="ac-dot">{{this.formatNumberWithDots(counter)}}</div>
          <div>{{ title }}</div>
        </div>
        <div class="ac-amount">
          <strong>&euro; {{ this.formatNumberWithDots(this.amountCounter) }}</strong>
          van de
          <strong>&euro; {{ this.formatNumberWithDots(this.amountGoal) }}</strong> opgehaald
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import 'es6-promise/auto';
import axios from 'axios';

export default {
  name: 'PUFormCounter',
  components: {},

  props: {
    formPath: {
      type: String,
      required: true,
    },
    widgetType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    offset: {
      type: Number,
      required: false,
      default: 0,
    },
    amountOffset: {
      type: Number,
      required: false,
      default: 0,
    },
    progressGoal: {
      type: Number,
      required: false,
      default: 0,
    },
    amountGoal: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      stats: null,
      version: process.env.PACKAGE_VERSION || '',
      radius: 90,
      strokeDashoffset: 0,
      amountPercentage: 0,
      progressPercentage: 0,
    };
  },

  computed: {
    counterAsParts() {
      // split large number into parts so that the (weird) milieudefensie
      // markup can deal with this.
      // e.g. 1234567 will become: 1. 2 3 4. 5 6 7
      const formatted = this.counter.toLocaleString('nl-NL');
      const formattedAsString = formatted.toString();
      const result = [];
      for (let x = 0; x < formattedAsString.length; x += 1) {
        let c = formattedAsString.charAt(x);
        if (
          x + 1 < formattedAsString.length
          && [',', '.'].includes(formattedAsString.charAt(x + 1))
        ) {
          c += formattedAsString.charAt(x + 1);
          x += 1;
        }
        result.push(c);
      }
      return result;
    },
    counter() {
      const total = this.stats ? this.stats.total : 0;
      return total + this.offset;
    },
    amountCounter() {
      const amount = this.stats ? this.stats.amount : 0;
      return amount + this.amountOffset;
    },
  },

  methods: {
    formatNumberWithDots(someNumber) {
      return someNumber.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
    },
    getCounterStats() {
      const url = `${process.env.VUE_APP_NODERED_URL}${process.env.VUE_APP_NODERED_COUNTER_URL}`;
      const params = { formPath: this.formPath };
      axios.get(url, { params }).then((response) => {
        this.stats = response.data;
      });
    },
    updateProgress() {
      let total = this.stats ? this.stats.total : 0;
      total += this.offset;

      const goal = this.progressGoal ? this.progressGoal : total;

      let percentage = (total / goal) * 100;

      if (percentage < 0) {
        percentage = 0;
      }

      if (percentage > 100) {
        percentage = 100;
      }

      if (total) {
        // eslint-disable-next-line radix
        this.progressPercentage = parseInt(percentage);
      }
    },
    updateAmount() {
      let amount = this.stats ? this.stats.amount : 0;
      amount += this.amountOffset;

      const goal = this.amountGoal ? this.amountGoal : amount;

      let percentage = (amount / goal) * 100;

      // eslint-disable-next-line radix
      const c = Math.PI * (parseInt(this.radius) * 2);

      if (percentage < 0) {
        percentage = 0;
      }

      if (percentage > 100) {
        percentage = 100;
      }

      if (amount) {
        // eslint-disable-next-line radix
        this.amountPercentage = parseInt(percentage);
        this.amount = amount;
        this.strokeDashoffset = ((100 - percentage) / 100) * c;
      }
    },
  },

  mounted() {
    this.getCounterStats();
    this.updateAmount();
    this.updateProgress();
  },

  watch: {
    formPath() {
      this.getCounterStats();
    },
    stats() {
      this.updateAmount();
      this.updateProgress();
    },
    amountGoal() {
      this.updateAmount();
      this.updateProgress();
    },
    progressGoal() {
      this.updateAmount();
      this.updateProgress();
    },
    offset() {
      this.updateAmount();
      this.updateProgress();
    },
    amountOffset() {
      this.updateAmount();
      this.updateProgress();
    },
  },
};
</script>

<style lang="scss">
.version {
  color: rgba(0, 0, 0, 0.1);
}
.AmountContainer {
  display: flex;
  margin-top: 1em;
  .AmountContainer-percentage {
    text-align: center;
    display: block;
    height: 200px;
    width: 200px;
    border-radius: 100%;
    font-weight: 800;
    position: relative;

    #svg circle {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 1s linear;
      stroke: #999;
      stroke-width: 1em;
    }

    #svg #bar {
      stroke: #69be28;
    }
  }

  .AmountContainer-percentage:after {
    position: absolute;
    display: block;
    height: 160px;
    width: 160px;
    left: 50%;
    top: 50%;
    color: #999;
    content: attr(data-pct) "%";
    margin-top: -80px;
    margin-left: -80px;
    border-radius: 100%;
    line-height: 160px;
    font-size: 2em;
  }

  .AmountContainer-info {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    .ac-info {
      display: flex;
      align-items: center;
    }
    .ac-amount {
      margin-top: 1em;
    }
    .ac-dot {
      margin-right: 0.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #69be28;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 16px;
      height: 60px;
      overflow: hidden;
      min-width: 60px;
    }
  }
}
.ProgressContainer {
  .pc-progress {
    background-color: #999;
    border: 1px solid #fff;
    height: 1.5625rem;
    margin: 0.125rem 0;
    padding: 0.125rem;

    .pc-meter {
      background: #69be28;
      display: block;
      height: 100%;
      float: left;
      transition: 0.5s linear;
    }
  }
}
</style>
