import 'es6-promise/auto';
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import PUFormCounter from './components/PUFormCounter.vue';

// Enable the plugin
Vue.use(vueCustomElement);

// Enable the plugin
Vue.use(PUFormCounter);

// Register your component
Vue.customElement('pu-form-counter', PUFormCounter, {
  // Additional Options: https://github.com/karol-f/vue-custom-element#options
  shadow: false,
  // shadowCss: `@import '${process.env.VUE_APP_STATIC_URL}/pu-form-counter.css';`,
});
